.settings-static-answers {
  margin-top: 30px;

  ul {
    list-style-type: none;
    padding-left: 0;

    button {
      margin: 3px 5px;
      float: none;
    }
  }

  button {
    float: right;
    margin: 0 3px;
  }
}
