.sidebar {
  height: 100vh;
  padding-top: 100px;
  border: 0 solid #e8e8e8;
  border-right-width: 1px;

  .ant-menu-item {
    background: transparent;
  }

  h2 {
    font-size: 13px;
    padding-left: 25px;
    text-transform: uppercase;
  }

  h3 {
    padding-left: 25px;
    text-transform: uppercase;
  }

  .ant-menu.ant-menu-inline {
    background-color: transparent;
    border: 0;
  }
}
