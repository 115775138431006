.reviews {
  .ant-col.ant-form-item-control-wrapper, .ant-row.ant-form-item {
    width: 100%;
  }

  .loading {
    width: 100%;
    text-align: center;
    padding: 20px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  .button {
    float: left;
  }
}
