@import "./_mixins.scss";
@import "./_variables.scss";

@import "./components/Header.scss";
@import "./components/SideBar.scss";
@import "./containers/Home.scss";
@import "./containers/App.scss";
@import "./containers/Reviews.scss";
@import "./containers/Templates.scss";
@import "./components/SingleReview.scss";
@import "./components/SingleTemplate.scss";
@import "./components/AnswersByLang.scss";
@import "./components/Filters.scss";
@import "./components/SettingsStaticAnswers.scss";
@import "./components/SettingsStaticAnswersForm.scss";

body {
  margin: 0 0;
}

.content {
  padding: $content-top-padding 30px 0 30px;
}


