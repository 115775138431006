.messages {
  padding-right: 10px;
  border-right: 1px solid #e8e8e8;

  .title {
    font-weight: bold;
    text-transform: uppercase;
  }

  ul {
    list-style-type: none;
  }

  .preview {
    background-color: #e8e8e8;
    padding-left: 20px;
  }

  .ant-row.ant-form-item, .ant-col.ant-form-item-control-wrapper {
    width: 100%;
  }

  button {
    margin: 0 5px;
  }
}
