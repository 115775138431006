.app {
  .ant-breadcrumb, .ant-breadcrumb-separator, .ant-breadcrumb-link {
    text-transform: uppercase;
  }

  .breadcrumb {
    img {
      margin-right: 10px;
    }
    margin-bottom: 20px;
  }
}
