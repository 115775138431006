.filters {
  margin-top: 2%;

  .filter-caption {
    font-weight: bold;
    margin-right: 1%;
  }

  label {
    text-transform: capitalize;
  }

  .string, .text-input, .warning {
    margin-top: 1%;
  }

  .ant-input-group-addon span {
    font-weight: normal;
  }

  .anticon {
    margin-right: 1%;
  }
}
