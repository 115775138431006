.single-review {
  margin-top: 20px;

  .buttons {
    float: right;
  }

  button {
    margin: 10px;
  }

  .reply {
    background-color: #e8e8e8;
  }
}
