.single-template {
  margin-top: 20px;

  .heading {
    margin-bottom: 30px;
  }

  .ant-row {
    line-height: 3;
  }

  .filters {
    padding-left: 10px;
    line-height: 10px;

    .filter-title {
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .ant-col {
    padding: 0 10px;
  }

  .ant-input-number, .ant-select {
    width: 100%;
  }
}
