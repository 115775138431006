header {
  background: #ffffff;
  text-transform: uppercase;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  line-height: 50px;
  padding: 10px 15px;

  border-bottom: 1px solid #e8e8e8;
}
